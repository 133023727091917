import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { INFO_MYOTHERNEST } from '../../../data/const/myothernest.info';
import { HeaderService } from '../../../core/services/header.service';
import { SEOService } from '../../../core/services/seo.service';
import { Utils } from '../../../core/utils/Utils';
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-privacy-notice',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './privacy-notice.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyNoticeComponent { 

  readonly email : string = INFO_MYOTHERNEST.email[0];
  readonly companyName : string = INFO_MYOTHERNEST.company_name;
  readonly address : string =  INFO_MYOTHERNEST.office.address + ", "+INFO_MYOTHERNEST.office.county+" "+INFO_MYOTHERNEST.office.number + ", "+INFO_MYOTHERNEST.office.eircode +" "+INFO_MYOTHERNEST.office.country;
  static readonly version : string = "1.1";

  readonly vers : string = PrivacyNoticeComponent.version;

  readonly lastUpdate : string ="Last updated January 31, 2024";

  @ViewChild('contenidoPagina') contenidoPagina !: ElementRef


  constructor(private _headerService : HeaderService, private seoService : SEOService){
    this.seoService.setDescription("Discover how My Other Nest protects your personal data. Read our Privacy Policy to understand what information we collect, how we use it, and your rights. Committed to transparency and security, we ensure your privacy is our priority. Get informed today!");
    this._headerService.hideImg();
  } 


  is_test(){
    return !AppComponent.PRODUCTION
  }

  download(){
    Utils.descargarPDF(this.contenidoPagina,PrivacyNoticeComponent.version);
  }
}
